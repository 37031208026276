/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint eqeqeq: 0 */

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import history from "./history";
//import React from "react";
import './App.scss';

//import { getConfig } from "./config";
//const config = getConfig();

import FrontPage from './FrontPage';
import Callback from './Callback';

function App() {
  return (
    <Router history={history}>
      <Routes>
        <Route path="/" element={<FrontPage/>} />
        <Route path="/callback" element={<Callback/>} />
      </Routes>
    </Router>
  );
}

export default App;
