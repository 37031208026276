import React from "react";
import loading from "./loading.svg";

import './App.scss';

const Loading = () => (
  <div style={{width:'100%', textAlign:'center'}} className="spinner">
    <img src={loading} alt="Loading" />
  </div>
);

export default Loading;