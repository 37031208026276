/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint eqeqeq: 0 */

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './App.scss';

import Loading from "./Loading";

import { getConfig } from "./config";
const config = getConfig();

var MobileDetect = require('mobile-detect');

function FrontPage() {
  const { websocket1 = config.websocket1, userApiOrigin = config.userApiOrigin, dataApiOrigin = config.dataApiOrigin } = getConfig();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [state, setState] = useState({
    initialised: false,
    responseData: null,
    addresses: [],
    screenSize: null,
    gettingAddresses: false,
    newAddress: null,
    ws1: null,
    tablet: false,
    error: null,
  });

  const myStateRef = React.useRef(state);
  const setMyState = data => {
    myStateRef.current = data;
    setState(data);
  };

  const [ isMe, setIsMe ] = useState(false);

  useEffect(() => {
    if (state.newAddress !== null) newAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [state.newAddress]);

  const connectWebsocket1 = async () => {
    try {      
      const token = await getAccessTokenSilently();
      const ws1 = new WebSocket(`${websocket1}`)
      
      ws1.onopen = () => {
        let msg = {
          token: token,
          sub: user.sub
        };
        ws1.send(JSON.stringify(msg));
        setMyState({
          ...myStateRef.current,
          OPEN: true,
        }); 
      }
      ws1.onmessage = evt => {    
        const message = JSON.parse(evt.data);
        if (message.operationType === 'update') {
          let addresses = myStateRef.current.addresses.slice(0);

          //console.log('_id: '+message.documentKey._id);
          var index = -1;
          var filteredObj = addresses.find(function(item, i){
            //console.log('item._id: '+item._id);
            if(item._id == message.documentKey._id){
              //console.log('found: '+item._id);
              index = i;
              return item;
            }
          });          

          for (const [key, value] of Object.entries(message.updateDescription.updatedFields)) {
            //console.log(`${key}: ${value}`);
            filteredObj[key] = value
          }

          var newAddress = state.newAddress;
          if (filteredObj.confirmed) {
            newAddress = state.newAddress === null ? true : !state.newAddress;
          }

          addresses[index] = filteredObj;

          setMyState({
            ...myStateRef.current,
            addresses: addresses,
            newAddress: newAddress
          });
          //console.log('update: '+JSON.stringify(filteredObj));
        }
      }
      ws1.onclose = () => {
        setTimeout(() => {
            connectWebsocket1();
        }, "10000");
        setMyState({
          ...myStateRef.current,
          OPEN: false,
        });
      }

      return function cleanup() {
        ws1.close();
      };
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (isAuthenticated) connectWebsocket1();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [isAuthenticated]);  

  function colorSchemeChange (onChange) {
    const media = window.matchMedia('(max-width: 600px)')
   
    handleChange()
   
    if ('addEventListener' in media) {
     media.addEventListener('change', handleChange)
    } else if ('addListener' in media) {
     media.addListener(handleChange)
    }
   
    return remove
   
    function handleChange () {
     const screenSize = media.matches
      ? 'small'
      : 'big'
     onChange(screenSize)
    }
   
    function remove () {
     if ('removeEventListener' in media) {
      media.removeEventListener('change', handleChange)
     } else if ('removeListener' in media) {
      media.removeListener(handleChange)
     }
     onChange = null
    }
  }

  /*useEffect(() => {
    if (state.tablet) connectWebsocket1();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [state.tablet]);*/

  useEffect(() => {
    var md = new MobileDetect(window.navigator.userAgent);
    if( md.tablet() ) {
      console.log('TABLET DETECTED');
      setMyState({
        ...myStateRef.current,
        tablet: true,
      });
    }

    const remove = colorSchemeChange(function(screenSize) {
      setMyState({
        ...myStateRef.current,
        screenSize: screenSize,
      });
    });

    return function cleanup() {
      remove();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMyState({
        ...myStateRef.current,
        initialised: true,
      });
    }, "2000")
  }, []);

  useEffect(() => {
    //if (window.performance) {
      if (performance.navigation.type == 1) {
        //alert( "This page is reloaded" );
        if (isAuthenticated) {
          callApiIsMe();
        }
      } else {
        //alert( "This page is not reloaded");
        /*setTimeout(() => {
          getAddresses();
        }, "2000")*/
      }
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [window.performance]);

  useEffect(() => {
    //alert( "isAuthenticated has changed");
    if (!myStateRef.current.initialised && isAuthenticated) {
      callApiIsMe();
    } else {
      if (myStateRef.current.initialised) {
        //alert( "Already initialised");
      }
      if (isAuthenticated) {
        //alert( "Already authenticated");
      }      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [isAuthenticated]);

  const callApiIsMe = async () => {
    try {      
        const token = await getAccessTokenSilently();

        const response = await fetch(`${userApiOrigin}/api/isme`, {
            method: 'POST',
            body: JSON.stringify({user: user}),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const responseData = await response.json();

        setIsMe(responseData.result);
        getAddresses();   
    } catch (error) {
      console.log(error);
    }
  };

  const logoutWithRedirect = () =>
  logout({    
    returnTo: window.location.origin,
  });

  const getAddresses = async (e) => { 
    try {        
        //alert( "getAddresses");
        const token = await getAccessTokenSilently();

        const response = await fetch(`${dataApiOrigin}/api/get-addresses`, {
          method: 'GET',
          headers: {
              Authorization: `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
        });

        const responseData = await response.json();

        //console.log(new Date() + ': ' + JSON.stringify(responseData));

        setMyState({
          ...myStateRef.current,
          initialised: true,
          addresses: responseData.addresses,
        });
    } catch (error) {
    }
  };

  const newAddress = async (e) => {
    try {
        const token = await getAccessTokenSilently();

        const response = await fetch(`${dataApiOrigin}/api/new-address`, {
          method: 'GET',
          headers: {
              Authorization: `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
        });

        const responseData = await response.json();

        var addresses = myStateRef.current.addresses.slice(0);
        addresses.unshift(responseData.address);

        if (responseData.address) {          
          setMyState({
            ...myStateRef.current,
            addresses: addresses,
          });
        }
    } catch (error) {
    }
  };

  return (
    <>
      {myStateRef.current.initialised ? (
      <>
      <h1><span style={{"color": "#07FFFF"}} className="brand-name"><em>Weevil</em></span><span style={{"color": "#ddd"}}>corp</span></h1>

      <p>For support please contact <a href="mailto: support@weevilcorp.com">support@weevilcorp.com</a>.</p>

      {isAuthenticated && (
      <p className='p-connection-status'>
      <a href="/files/new-app/1.5/app-release.apk"><em>app-release.apk</em></a>&nbsp;
        {myStateRef.current.OPEN && (
          <span className={myStateRef.current.tablet ? 'connection-status connection-status-tablet' : 'connection-status'}>CONNECTED</span>
        )}
      </p>
      )}

      {!isAuthenticated && (
        <p>
          <a href="#" onClick={() => loginWithRedirect()}>Log in</a>&nbsp;&nbsp;&nbsp;
          <a href="#" onClick={() => loginWithRedirect()}>Register</a>
        </p>
      )}

      {isAuthenticated && (
        <>
        <a href="#" style={myStateRef.current.screenSize == "small" ? {"fontSize":"12px"} : {}} onClick={() => logoutWithRedirect()}>Log out, {user.email}</a>
        &nbsp;
        {myStateRef.current.screenSize == "big" && (
        <>&nbsp;&nbsp;&nbsp;</>
        )}
        <font style={{"fontSize": "12px"}}>
        Minimum amount: <em>0.000130</em>
        </font>
        {//isMe && (
        true && (
          <>
            {false && (
            <>
              <a href="#" onClick={() => getAddresses()}>Get Addresses</a>
              &nbsp;
              <a href="#" onClick={() => newAddress()}>New Address</a>
            </>
            )}
            <br />
            <br />
            {myStateRef.current.addresses.length > 0 && (
              <>
              <table>
              <tbody>
              {myStateRef.current.addresses.map((address, index) => 
                <tr className="tr-address" style={address.used ? {"color":"#aaa"} : {}} key={index}>                    
                    <td style={!address.confirmed ? {"paddingBottom":"10px"} : {}}>
                        {/*?amount=.000053%26label=Weevilcorp%26message=Payment*/}
                        {!address.used ?
                            (<a href={"bitcoin:"+address.address}>
                            {address.address}
                            </a>)
                        :
                          address.address
                        }
                    </td>
                    <td nowrap="true" style={!address.confirmed ? {"paddingBottom":"10px"} : {}}>
                      {address.used ? (address.confirmed ? " (confirmed)" : (address.pending ? " (pending)" : " (problem)")) : 
                          <>
                          &nbsp;
                          <a href={"https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=bitcoin:"+address.address}>
                          QR code
                          </a>
                          </>
                      }
                    </td>
                    {false && (
                      <>
                      <br />
                      {"info: "+JSON.stringify(address.info)}
                      </>
                    )}                    
                </tr>)}
                </tbody>
                </table>
              </>
            )}
          </>
        )}
        </>
      )}
      </>
      ) : <Loading />}
    </>
  );
}

export default FrontPage;
