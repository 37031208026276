import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "./config";
import Loading from "./Loading";

const config = getConfig();

var url = "";
if (config.production) {
  url = config.url;
} else {
  url = config.devUrl;
}

const Callback = () => {
    const navigate  = useNavigate();

    const { userApiOrigin = config.userApiOrigin } = getConfig();

    const {
        user,
        getAccessTokenSilently,
        logout,
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();

            const response = await fetch(`${userApiOrigin}/api/user`, {
                method: 'POST',
                body: JSON.stringify({user: user}),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            const responseData = await response.json();

            if (responseData.error) {
                logout({
                    returnTo: url,
                });
            } else {
                navigate('/');
            }            
        } catch (error) {
        }
    };

    useEffect(() => {
        callApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    return null;
};

export default withAuthenticationRequired(Callback, {
  onRedirecting: () => <Loading />,
});